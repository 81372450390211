import './list.scss';
import dataApi from '@/api/manage/refund.js';
import member from '@/api/member/member';

export default {
  name: 'manage-refund',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        editBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        delBtn: true,
        delBtnText: '处理',
        editTitle: '退单审核',
        column: [
          {
            label: '订单号',
            prop: 'order_sn',
            type: 'input',
            search: 'true',
            disabled: true
          },
          {
            label: '退单产品',
            prop: 'product_name',
            disabled: true
          },
          {
            label: '退款金额',
            prop: 'return_amount',
            disabled: true
          },
          {
            label: '退单原因',
            prop: 'reason'
          },
          {
            label: '申请会员',
            prop: 'member_name',
            disabled: true,
            search: true
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            editDisplay: false,
            dicData: [
              {
                label: '已完成',
                value: 5
              },
              {
                label: '待处理',
                value: 1
              },
              {
                label: '处理中',
                value: 2
              },
              {
                label: '退款中',
                value: 3
              },
              {
                label: '已拒绝',
                value: 4
              }
            ],
            search: 'true',
            prop: 'status',
            
            span: 12
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            label: '处理时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'handle_time'
          },
          {
            label: '操作员',
            addDisplay: false,
            editDisplay: false,
            prop: 'handle_man'
          }
        ]
      },
      searchData: {},
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              member.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      dialogVisible: false,
     
      delDa: {}
    };
  },
  computed: {
    delOp() {
      return {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        submitText: '立即审核',
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '订单号',
            prop: 'order_sn',
            type: 'input',
            disabled: true
          },
          {
            label: '退款金额',
            prop: 'return_amount',
            disabled: true
          },
          {
            label: '退单原因',
            prop: 'reason'
          },
          {
            label: '申请会员',
            prop: 'member_name',
            disabled: true,
            search: true
          },
          {
            label: '占位费类型',
            type: 'select',
            prop: 'charge_type',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            editDisplay: true,
            dicQuery: {
              dict_type: 'perch'
            },
            rules: [
              {
                required: true,
                message: '请选额占位费',
                trigger: 'blur'
              }
            ],
            change: (val) => {
              if (val.value === '0') {
                this.delDa.custom = 0;
              } else if (val.value === 'custom') {
                this.delDa.custom = '';
              } else {
                this.delDa.custom = Number(val.value);
              }
            },
            value: '0',
            hide: true
          },
          {
            label: '占位费',
            type: 'input',
            editDisplay: true,
            disabled: this.delDa.charge_type !== 'custom',
            prop: 'custom',
            rules: [
              {
                required: true,
                message: '请输入自定义占位费',
                trigger: 'blur'
              }
            ],
            hide: true
          },
          {
            label: '实退金额',
            type: 'input',
            formslot: true,
            disabled: true,
            prop: 'charge_fee',
            addDisplay: false,
            editDisplay: true,
            hide: true
          },
          {
            type: 'select',
            label: '审核',
            prop: 'audit_status',
            dicData: [
              {
                label: '同意退单',
                value: 1
              },
              {
                label: '拒绝退单',
                value: 5
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择审核操作'
              }
            ],
            required: true
          },
          {
            label: '审核备注',
            span: 24,
            type: 'textarea',
            prop: 'handle_note',
            disabled: false
          }
        ]
      };
    }
  },
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 删除
    rowDel(row) {
      this.delDa = row;
      this.dialogVisible = true;
    },
    handleOff() {
      this.delDa = {};
      this.dialogVisible = false;
    },
    // 修改
    rowUpdate(form, done) {
      if (form.return_amount < Number(form.custom)) {
        done();
        this.$message.error('退还金额必须大于或等于0');
        return false;
      }
      dataApi.update.r(form).then(() => {
        this.$message.success('操作成功');
        this.delDa = {};
        this.getList();
        this.dialogVisible = false;
      }).catch(() => {
        done();
      });
      done();
    },
    // 导出
    searchExcel(params) {
      if (this.activitySearchFo.table) {
        params.activity_id = this.activitySearchFo.table;
      }
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.star_ttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.member_name;
      delete params.activity_title;
      delete params.datetime;
      // 导出
      dataApi.getExport.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
