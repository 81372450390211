import http from '@/utils/http';

/**
 * 退单管理
 */
export default {
  getList: {
    p: 'post,/order/refund_apply/getlist',
    r: (data) => http({ url: '/order/refund_apply/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/order/refund_apply/update',
    r: (data) => http({ url: '/order/refund_apply/update', method: 'post', data })
  },
  del: {
    p: 'post,/order/refund_apply/delete',
    r: (data) => http({ url: '/order/refund_apply/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/order/refund_apply/getone',
    r: (data) => http({ url: '/order/refund_apply/getone', method: 'post', data })
  },
  exportFile: {
    p: 'post,/order/refund_apply/export',
    r: (data) => http({ url: '/order/refund_apply/export', method: 'post', data })
  }
};
