<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
        :data="tableData"
        :option="tbleOption"
        @refresh-change="getList"
        @row-del="rowDel"
        @on-load="onLoad"
        @search-change="searchChange"
        @search-reset="searchReset"
        :page.sync="page"
    >
    <template slot="member_nameSearch">
      <div style="height: 32px;width: 100%">
        <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
      </div>
    </template>
    
    <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button
          icon="el-icon-download"
          :size="size"
          @click="searchExcel(row,size)"
        >导出</el-button>
    </template>
    </avue-crud>
    <!-- 审核 -->
    <el-dialog
      title="退单审核"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
    >
      <avue-form
        v-model="delDa"
        ref='form'
        :option="delOp"
        @submit="rowUpdate"
        @reset-change="handleOff"
      >
        <template slot="charge_fee">
          <div v-if="delDa.charge_type === 'custom'">
            {{ delDa.return_amount - delDa.custom }}
          </div>
          <div v-else>
            {{ delDa.return_amount - delDa.custom * delDa.return_amount }}
          </div>
        </template>
       
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './refund';

export default List;
</script>
